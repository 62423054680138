<template>
  <header>
    <the-main-nav></the-main-nav>
  </header>
  <section class="mainSection">
    <div class="content">

<h1>Privacy Policy</h1>
<p>Effective Date: November 16, 2021</p>
<p>Agni knows that you care about how your Personal Data is used therefore we take your privacy seriously. Please read the following to learn more about our privacy policy. This article describes how your data is treated when you acces www.agni.rocks and install one of its plugin.  By accessing or using the Service, you acknowledge that you accept the practices and policies outlined in this Privacy Policy.

</p>
<h2>What Personal Data Does Agni Collect?</h2>
<ol>
  <li>User information, such as the name, username, password, email address, screenshots , settings, and any other information connected with your user account.</li>
  <li>Band information, such as user-provided biography, location, and tags.</li>
  <li>We use Google Analytics to collect and process data.  You can learn about Google’s practices by going to https://www.google.com/policies/privacy/partners/. If you wish to opt-out from their services, use add-on, available at https://tools.google.com/dlpage/gaoptout</li>
  <li>A temporary security token, which is generated by the agni.rocks services and exchanged by Agni and agni.rocks</li>
  <li>A site validation token, generated by Agni</li>
  <li>The URL of your WordPress login page</li>
  <li>The URL for a temporary validation page used by Agni to ensure your site is valid for registration at the agni.rocks
cloud services. This validation page will be validated by the agni.rocks services for existence. After successful site
validation, Agni will remove the hook for this page (no longer respond on that specific URL).</li>
  <li>The email address stored in Agni (to which Agni sends test reports)</li>
  <li>Optionally a mobile phone number for sending reports via Telegram. This service is not activated as of 2020-10-06, but
will be available at a later date.</li>
  <li>Name of your blog (used to refer to as an identifier in the emails sent to you)</li>
  <li>The username of the test user (used for testing the login page)</li>
  <li>The password of the test user (used for testing the login page)</li>
  <li>User registration token as received from the agni.rocks services via email, once entered on the settings page of Agni</li>
  <li> The IP address of the WordPress installation</li>
  <li>The "user agent" communicating with the agni.rocks services. This consists of the WordPress version in use plus its
URL, looking e.g. like this: "WordPress/5.4.2; https://SomeDomainHere/WordPress"</li>
</ol>

<h2>For Which Purposes Will Agni Use Your Personal Data?</h2>
<p>The Personal Data collected by Agni is used for the following purposes:</p>
<ol>
  <li>To allow you to use the Agni cockpit and provided plugins</li>
  <li>To communicate with you about products, services, information or any other related communication related Agni and its services</li>
  <li>To process sales and purchases, to transfer money, and to provide the expected receipts, statistics, and reports to the band and its customers;</li>
  <li>To perform accounting, auditing, billing, reconciliation, and collection activities;</li>
  <li>To respond to your inquiries;</li>
  <li>To perform data analyses and data aggregation (including anonymization of Personal Data);</li>
  <li>To comply with law enforcement requests and other legal obligations;</li>
  <li>To apply and enforce our Terms of Use and other agreements;</li>
  <li>To protect the rights, property, or safety of Bandcamp, our employees, our users, or others, including by taking measures to prevent fraud and reduce credit risk;</li>
</ol>

<p>We also may use the information in other ways for which we provide specific notice at the time of collection.</p>

<h2>When Does Agni Collect Personal Data Relating To You?</h2>
<ol>
  <li>You have consented to the use of your Personal Data;</li>
  <li>We need your Personal Data to provide you with services and products requested by you, or to respond to your inquiries;</li>
  <li>We have a legal obligation to use your Personal Data;</li>
  <li>We have a legitimate interest in using your Personal Data. This includes our legitimate interests to ensure and improve the safety, security and performance of our products and services.</li>
</ol>

<h2>How Does Agni Receive Personal Data About To You?</h2>
<p>We may receive Personal Data relating to you from:</p>
  <ol>
    <li>You directly when you provide us with information about you;</li>
    <li>Using our plugins</li>
    <li>Third parties that provide us with information about you.</li>
  </ol>

<h2>For How Long Does agni.rocks Keep Your Personal Data and how can you erase them?</h2>

<p>The resulting screenshots of the tests are stored on our servers for a maximum of 30 days after which they are deleted.
Accessing the agni.rocks services, including Agni using them, will be logged including date, time, IP address and user
agent. Log entries are kept for 120 days after which they are deleted.
Accounts created via Agni which are not active within 30 days will be deleted.
Accounts with continuously failing test results will be removed after a period of 120 days.
</p>

<p>Once your account has been set up and is active, you can turn off testing anytime by disabling the "scheduled test".
You can remove your account at any time by simply removing Agni, which will cause the plugin to delete the account
on agni.rocks or you can request your account to be removed by contacting support@agni.rocks</p>

<h2>How Will Agni Share the Personal Data It Receives?</h2>
<p>We value privacy and personal data of our customers are not for rent or sell to anyone. We share customer’s data only in following cases:</p>

<ol>
  <li>Compliance with the Law, Protection of Bandcamp and Others: We may access, preserve, and disclose collected information, if we believe doing so is required or appropriate to: comply with law enforcement requests and legal process, such as a court order or subpoena; enforce or apply our conditions of use and other agreements; or protect the rights, property, or safety of Bandcamp, our employees, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.</li>
  <li>With Your Consent: We would aks for your permission if there would be need of sharing customer’s data with third party.</li>
</ol>

<h2>What Choices And Rights Do You Have Regarding Your Personal Data?</h2>
<p>Under data protection law, you have rights including:</p>
<ol>
  <li>Your right of access - You have the right to ask us for copies of your personal information.</li>
  <li>Your right to rectification - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</li>
  <li>Your right to erasure - You have the right to ask us to erase your personal information in certain circumstances.</li>
  <li>Your right to restriction of processing - You have the right to ask us to restrict the processing of your personal information in certain circumstances.</li>
  <li>Your right to object to processing - You have the the right to object to the processing of your personal information in certain circumstances.</li>
  <li>Your right to data portability - You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.</li>
  <li>You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.</li>
  <li>Please contact us at agni@agni.rocks if you wish to make a request.</li>
</ol>

<h2>Does Agni Transfer Your Personal Data Abroad?</h2>
<p>The data you enter will be stored securely in our servers located in the European Union (EU) according to data
protection regulation laws (EU GDPR).</p>

<h2>Changes to this Privacy Policy</h2>
<p>Agni may amend this Privacy Policy from time to time. Use of information we collect now is subject to the Privacy Policy in effect at the time such information is used. If we make changes in the way we use Personal Data, we will notify you by posting an announcement on our Service or sending you an email. Users are bound by any changes to the Privacy Policy when they use the Service after such changes have been first posted.
How to Contact Us
If you have any questions, you can use are ‘Contact us’ formular or send an email to agni@agni.rocks</p>

    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TheMainNav from '@/components/layout/TheMainNav.vue';

export default defineComponent({
  components: {
    TheMainNav,
  },
});
</script>

<style lang="scss" scoped>
* {
  font-family: "pm";
  display: block;
  font-size: 13px;

  @media (max-width: 500px) {
    font-size: 11px;
  }
}
.mainSection {
  padding: 100px 0;
}
h1 {
  display: block;
  color: #5828ff;
  font-size: 4em;
  font-family: "pm-b";
  margin-top: 50px;
  margin-bottom: 25px;
}
h2 {
  color: #5828ff;
  font-size: 2em;
  margin-top: 30px;
  margin-bottom: 10px;
  line-height: 1.5em;
}
p {
  color: #747474;
  font-size: 18px;
  max-width: 900px;

  &.warranty {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 30px;
  }
}

ol {
  list-style: decimal;
  color: #747474;
  max-width: 900px;
  margin-top: 30px;
}
  li {
    display: list-item;
    font-size: 18px;
    margin: 10px 0px 10px 45px;
  }

</style>
